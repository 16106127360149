import { COLOURS } from './colours';
export function getMargin(partnerPrice, clientPrice) {
  if (clientPrice === 0) {
    return 0;
  }
  const profit = getProfit(partnerPrice, clientPrice);
  const margin = profit / clientPrice;
  return margin;
}
export function getProfit(partnerPrice, clientPrice) {
  return clientPrice - partnerPrice;
}
export function getMarginTagColor(margin) {
  if (margin < 0.5) {
    return COLOURS.red;
  }
  if (margin < 0.65) {
    return COLOURS.orange;
  }
  return COLOURS.green;
}
export function getDesiredCost(clientPrice, margin = 0.65) {
  return clientPrice - clientPrice * margin;
}